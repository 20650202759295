import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import notificationService from '../../../services/notifications'
import companyUserService from '../../../services/companyUsers'
import companyService from '../../../services/companies'
import sessionHandler from '../../../utilities/SessionHandler'

export default function Send() {

    const navigateTo = useNavigate()

    const [messageStatus, setMessageStatus] = useState('')
    const [messageText, setMessageText] = useState('')
    const [messageClass, setMessageClass] = useState('alert alert-dismissible fade')
    const [recaptchaToken, setRecaptchaToken] = useState(null)
    const [companyUser, setCompanyUser] = useState(null)
    const [company, setCompany] = useState(null)

    const [formData, setFormData] = React.useState({
        fromName: '',
        fromEmail: '',
        fromCompany: '',
        notificationMessage: '',
        'g-recaptcha-response': ''
    })

    const credentials = sessionHandler.getParsedCredentials()

    const handleUserLoad = async () => {
        const result = await companyUserService.get(credentials.id)
        setCompanyUser(result)
    }

    const handleCompanyLoad = async () => {
        if (companyUser) {
            try {
                const result = await companyService.get(credentials.company_id)
                setCompany(result)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_SITE_RECAPTCHA_KEY, { action: "contactus" })
                .then(token => {
                    setRecaptchaToken(token)
                    setFormData({
                        ...formData,
                        fromName: companyUser.companyUserFullName ?? '',
                        fromEmail: companyUser.companyUserEmail ?? '',
                        fromCompany: company.companyName ?? '',
                        'g-recaptcha-response': token
                    })
                })
        })
    }

    useEffect(() => {
        // Add reCaptcha
        handleUserLoad()
        handleCompanyLoad()
    }, [])

    useEffect(() => {
        handleCompanyLoad()
    }, [companyUser])

    useEffect(() => {
        if (company) {
            if (company.companyName !== null || company.companyName !== '' || company.companyName !== undefined) {
                setFormData(
                    {
                        ...formData,
                        fromCompany: company.companyName,
                        fromEmail: companyUser.companyUserEmail,
                        fromName: companyUser.companyUserFullName
                    }
                )
            }
            const script = document.createElement("script")
            script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_SITE_RECAPTCHA_KEY
            script.addEventListener("load", handleLoaded)
            document.body.appendChild(script)
        }
    }, [company])

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        document.getElementById(event.target.name).classList.remove('is-invalid')
    }

    const btnSendOnClick = async () => {
        let newFormData = {
            ...formData,
            'g-recaptcha-response': recaptchaToken
        }
        const result = await notificationService.contactUs(newFormData)
        if (result.status === 'success') {
            setMessageStatus('success')
            setMessageClass('alert alert-dismissible fade show alert-success')
            setMessageText(result.message)
            if (companyUser && company) {
                setFormData({
                    ...formData,
                    notificationMessage: '',
                })
            }
            handleLoaded()
        }
        else {
            setMessageStatus('danger')
            setMessageClass('alert alert-dismissible fade show alert-danger')
            setMessageText(result.message)
            handleLoaded()
            if (result.status === 'validation_error') {
                result.error.forEach((error) => {
                    document.getElementById(error.field).classList.add('is-invalid')
                })
            }
        }
    }

    const btnAlertDismissOnClick = () => {
        setTimeout(() => {
            setMessageText('')
            setMessageStatus('')
            setMessageClass('alert alert-dismissible fade')
        }, 200)
    }

    return (
        <>
            <Navbar companyUser />
            <main className="theme-light">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-md-12 pb-5">
                            <h1 className="h4 fw-bold mb-5">¿Necesitas ayuda? envianos un mensaje</h1>
                            <form action="">
                                <div className="form-outline mb-3">
                                    <label className="form-label">Nombre Completo</label>
                                    <input
                                        type="text"
                                        id="fromName"
                                        name="fromName"
                                        className="form-control form-control-lg rounded-pill"
                                        placeholder="Ingrese su nombre completo"
                                        onChange={handleInputChange}
                                        value={formData.fromName}
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="form-outline mb-3">
                                    <label className="form-label">Nombre Empresa</label>
                                    <input
                                        type="text"
                                        id="fromCompany"
                                        name="fromCompany"
                                        className="form-control form-control-lg rounded-pill"
                                        placeholder="Ingrese el nombre de su empresa"
                                        onChange={handleInputChange}
                                        value={formData.fromCompany}
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="form-outline mb-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="fromEmail"
                                        name="fromEmail"
                                        className="form-control form-control-lg rounded-pill"
                                        placeholder="Ingrese su email"
                                        onChange={handleInputChange}
                                        value={formData.fromEmail}
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="form-outline mb-3">
                                    <label className="form-label">Mensaje</label>
                                    <textarea
                                        name="notificationMessage"
                                        id="notificationMessage"
                                        cols="30"
                                        rows="10"
                                        className="form-control form-control-lg"
                                        placeholder="Ingrese su mensaje"
                                        onChange={handleInputChange}
                                        required
                                        value={formData.notificationMessage}
                                    >
                                    </textarea>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div
                                        className="g-recaptcha"
                                        data-sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY}
                                        data-size="invisible"
                                    ></div>
                                    <input
                                        type="button"
                                        className="btn btn-primary rounded-pill my-3 px-5 py-2 text-white"
                                        value="Enviar consulta"
                                        onClick={btnSendOnClick}
                                    />
                                </div>
                                {
                                    messageStatus !== '' && (
                                        <div className="form-outline mb-3">
                                            <div className={messageClass}
                                                role="alert">
                                                {messageText}
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="alert"
                                                    aria-label="Close"
                                                    onClick={btnAlertDismissOnClick}
                                                ></button>
                                            </div>
                                        </div>
                                    )
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
